require('./bootstrap');

import { createApp } from 'vue';

let app = createApp()

// app.component('example-component', require('./components/ExampleComponent.vue').default);

app.mount("#app")


// ToastrD
window.toastr = require('toastr');


toastr.options.closeButton = true;
toastr.options.timeOut = 0;
toastr.options.extendedTimeOut = 0;

// select2

require('select2');
require('bs-stepper');
window.AOS = require('aos');


window.TempusDominus = require('@eonasdan/tempus-dominus').TempusDominus
